<template>
    <div>
        <v-row no-gutters align="center" justify="end">
            <v-btn depressed color="primary" rounded @click="openCreateDialog">
                <v-icon left>mdi-plus</v-icon>
                <span>New Document</span>
            </v-btn>

            <DocumentForm
                v-model="createDialog"
                title="New Document"
                :create-form="true"
                @closeDialog="closeCreateDialog"
                @addDocument="addDocument"
                formUser="true"
                :userId="userId"
            />
        </v-row>
        <v-data-table
            v-if="user"
            :items-per-page="6"
            :loading="loading"
            :items="documents"
            :headers="headers"
            class="my-4"
            :mobile-breakpoint="0"
        >
            <template v-slot:[`item.filename`]="{ item }">
                <a @click="click(item)">{{ item.filename }}</a></template
            >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon small @click="deleteItem(item)">
                    <v-icon small>mdi-trash-can</v-icon>
                </v-btn>

                <v-dialog
                    :retain-focus="false"
                    v-model="deleteDialog"
                    persistent
                    max-width="400px"
                >
                    <v-card>
                        <v-card-title class="text-h5"
                            >Delete Document</v-card-title
                        >
                        <v-card-text>
                            Are you sure you want to delete this document?
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="secondary"
                                @click="deleteDialog = false"
                            >
                                Close
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="deleteDoc" text color="error">
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { storage } from '@/services/firebase'
import DocumentForm from '@/components/Settings/DocumentForm'
import API from '@/services/api'
import moment from 'moment'
import { mapMutations } from 'vuex'
export default {
    name: 'UserDocuments',
    props: {
        id: String,
        user: Object,
    },
    components: {
        DocumentForm,
    },
    data() {
        return {
            folderDocuments: 'users_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            createDialog: false,
            loading: false,
            documents: [],
            docToDelete: null,
            error: null,
            deleteDialog: false,
            indexItem: null,
            errorMsg: null,
            userId: null,
            headers: [
                { text: 'DATE', value: 'createdOn', class: 'grey lighten-3' },
                { text: 'DESCRIPTION', value: 'name', class: 'grey lighten-3' },
                {
                    text: 'DOCUMENT',
                    value: 'filename',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    class: 'grey lighten-3',
                },
            ],
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openCreateDialog: function() {
            this.createDialog = true
        },
        closeCreateDialog: function() {
            this.createDialog = false
        },
        addDocument: function(document) {
            this.documents.push(document)
        },
        deleteUserDoc: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await this.deleteOnStorage(this.docToDelete.file)
                await API.deleteDocUser(this.id, this.docToDelete.id)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        deleteOnStorage: async function(file) {
            const path = `${this.companyId}/${this.folderDocuments}/${this.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(file)
            await refDoc.delete()
            return true
        },
        deleteItem(item) {
            this.deleteDialog = true
            this.docToDelete = item

            this.indexItem = this.documents.findIndex(
                x => x.id == this.docToDelete.id
            )
        },
        click: function(event) {
            window.location.assign(event.url)
        },
        deleteDoc() {
            this.documents.splice(this.indexItem, 1)
            this.deleteUserDoc()
        },
    },
    async created() {
        try {
            this.loadingError = false
            this.errorMsg = null
            this.loading = true
            this.userId = this.id
            const {
                data: { documents },
            } = await API.getUserDocuments(this.id)
            this.documents = documents
            this.documents.forEach(document => {
                document.createdOn = moment
                    .unix(document.createdOn._seconds)
                    .format('MMM/DD/YYYY HH:mm')
            })
            for (const doc of this.documents) {
                if (!doc.url) {
                    const path = `${this.companyId}/${this.folderDocuments}/${this.id}`
                    const storageRef = storage().ref(path)
                    const documentRef = storageRef.child(doc.file)
                    doc.url = await documentRef.getDownloadURL()
                    const meta = await documentRef.getMetadata()
                    doc.filename = meta.customMetadata.filename.replaceAll(
                        '+',
                        ' '
                    )
                }
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:hover {
    background: unset !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
</style>
